<template>
  <component
    :is="tag"
    ref="draggable"
    class="draggable-item"
    :class="{ presentDragTarget, presentDragAgent }"
    :draggable="draggable"
    @dragstart="dragStart"
    @dragover.prevent="presentDragTarget=true"
    @dragleave="presentDragTarget = false"
    @dragend="presentDragAgent=false;presentDragTarget=false"
    @drop.prevent="drop"
  >
    <slot :draggable-target-class="draggableTargetClass" />
  </component>
</template>

<script>
export default {
  props: {
    tag: { type: String, default: 'div' },
    value: { type: [ String, Number ], default: '' },
    draggable: { type: Boolean, default: true },
    type: { type: String, default: '' }
  },
  data: () => ({
    presentDragTarget: false,
    presentDragAgent: false,
    draggableTargetClass: 'draggable-target'
  }),
  methods: {
    isDraggableState ( ) {
      const draggableRef = this.$refs.draggable;
      const targetElem = draggableRef && draggableRef.querySelector( `.${this.draggableTargetClass}` );
      return !targetElem;
    },
    dragStart ( e ) {
      if ( !this.isDraggableState( e ) ) e.preventDefault();
      e.dataTransfer.setData( 'target', JSON.stringify({ dataKey: this.value, clientY: e.clientY, type: this.type }) );
      this.presentDragAgent = true;
    },
    drop ( e ) {
      this.presentDragAgent = false;
      this.presentDragTarget = false;
      const targetData = JSON.parse( e.dataTransfer.getData( 'target' ) );
      const direction = ( targetData.clientY - e.clientY ) < 0 ? 'downwards' : 'upwards';
      this.$emit( 'drop', { id: targetData.dataKey, direction, type: targetData.type });
    }
  }
};
</script>

<style lang="scss" scoped>
.draggable-item.presentDragTarget:not(.presentDragAgent) > a {
    outline: 2px dashed var(--app-primary-color);
}

tr.draggable-item.presentDragTarget {
    border-right: 2px dashed var(--app-primary-color);
    border-left: 2px dashed var(--app-primary-color);
}
</style>
