<template>
 <div class="uk-card uk-card-default uk-card-hover uk-padding-small uk-border-rounded">
      <div class="uk-grid-small uk-flex-middle" uk-grid>
        <div class="uk-card">
            <span v-if="logo === 'ar'"  class="uk-icon" >  
              <img src="@/assets/ar2.svg" uk-svg width="32"/>
            </span>
            <span v-else :uk-icon="logo"></span>        
        </div>
        <div class="uk-card uk-text-left uk-margin-left uk-text-truncate">
          <label class="uk-text-truncate">{{ name }}</label>
          <h2 class="uk-card-title uk-margin-small-top">{{ count }}</h2>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name: 'PublishingCard',
  props: {
    name: {
      type: String,
      required: true
    },
    count: {
      type: Number,
      default: 0
    },
    logo: {
      type: String,
      required: true
    }
  }
}
</script>
