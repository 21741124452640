<template>
  <div id="collections">
    <div class="uk-flex uk-flex-between">
      <h1 class="uk-text-left uk-margin-remove uk-width-2-5 uk-text-truncate"><span @click="navigateBack" style="text-decoration: none;"><span uk-icon="icon: chevron-left; ratio: 2"></span>{{$route.params.propertyName }}</span></h1>
      <div class="uk-flex-right" v-if="isMutable">
          <button style="padding-left:10px; padding-right:10px" :uk-tooltip="$t('Pages.Publishing.Languages')" aria-label="Languages" class="uk-button uk-button-primary uk-margin-small-right" type="button" @click="()=>toggle =!toggle">
            <i uk-icon="icon: world"></i> <i  v-if="!toggle" uk-icon="icon: triangle-down"></i>
            <i v-else uk-icon="icon: triangle-up"></i>
          </button>
          <button v-if="toggle" class="uk-button uk-button-primary new-collection-btn" href="#collection-lang-modal" uk-toggle>
            <span uk-icon="plus-circle"></span> {{ $t('Languages.AddLanguage') }}
          </button>
          <button class="uk-button uk-button-primary new-collection-btn uk-margin-small-left" href="#new-collection-modal" uk-toggle>
            <span class="uk-margin-small-right" uk-icon="plus-circle"></span> {{ $t('Pages.Publishing.NewFolder') }}
          </button>
      </div>
    </div>
    <hr />
    <PublishingDashboard :properties-data="collections" uk-scrollspy="target: > div; cls: uk-animation-slide-top-medium; delay: 50; offset-top: 500;"/>
    <hr />
    <div class="uk-text-center" v-if="!fetching && collectionNodes.length === 0 && isMutable">
      <span class="uk-text-meta">{{ $t('Pages.Collections.NoData') }}</span>
      <a class="uk-text-primary uk-margin-small-left" href="#new-collection-modal" uk-toggle>{{ $t('Pages.Publishing.NewFolder') }}</a>
    </div>

    <div class="uk-text-center uk-text-meta uk-margin-top" v-if="!fetching && collectionNodes.length === 0 && !isMutable">
      <span>{{ $t('Pages.Collections.NoFolders') }}</span>
    </div>
      <div v-if="toggle" class="uk-margin">
      <baseTable :tableHeaders="langTableHeaders" class="uk-margin-small-top">
      <tr
        v-for="(lang, i) in propertyLanguages" 
        :key="i"
        class="uk-items-center table-row">
          <td><span>{{ langTitle( lang ) }}</span> <span v-if="lang.language==='en-US'" class="uk-text-meta">{{$t('Languages.Default')}}</span></td>
          <td class="uk-text-truncate uk-preserve-width uk-width-large"><span>{{ lang.name }}</span></td>
          <td class="uk-text-truncate uk-width-large uk-preserve-width">{{ lang.headline }}</td>
          <td class="uk-text-truncate uk-width-2xlarge uk-preserve-width">{{ lang.description }}</td>
          <td class="uk-text-center">
            <div uk-icon="icon: pencil; ratio: 1" class="trash-icon" @click.stop="clickEditLang( lang )"/>
                <span v-if="deletingLangList.includes( lang )" class="trash-icon uk-margin-small-left" uk-spinner="ratio: 1" />
                <div v-else uk-icon="icon: trash; ratio: 1" class="uk-margin-small-left trash-icon" @click.stop="deleteLanguage( lang )" />
          </td>
        </tr>
      </baseTable>
    </div>
    <span v-if="fetching || loadingMore" uk-spinner />
    <baseTable v-if="!fetching && collectionNodes && collectionNodes.length > 0" :tableHeaders="tableHeaders" class="uk-margin-small-top">
        <DraggableItem
          v-for="collection in collectionNodes"
          :key="collection.id"
          v-model="collection.id"
          :draggable="isMutable"
          tag="tr"
          @drop="drop( $event, collection.id )">
          <td v-if="isMutable" class="icon-drag">
            <div uk-icon="icon: chevron-up; ratio: 0.8" />
            <div uk-icon="icon: chevron-down; ratio: 0.8" />
          </td>
          <RouterLink tag="td" :to="toContent( collection )" class="icon">
            <div uk-icon="icon: album; ratio: 1.3" :uk-tooltip="$t('Pages.Content.ContentTypes.Folder')"/>
          </RouterLink>
          <RouterLink tag="td" :to="toContent( collection )" class="icon-image">
            <ImageOrAbbrevation :src="collection.ref.icon ? collection.ref.icon.url : ''" :alt="collectionName(collection)" width="85px" imageClass='uk-border-rounded' />
          </RouterLink>
          <RouterLink tag="td" :to="toContent( collection )" class="uk-margin-remove-left name uk-width-2xlarge uk-preserve-width uk-text-truncate">{{ collectionName ( collection ) }}</RouterLink>
          <td />
          <RouterLink tag="td" :to="toContent( collection )" class="access">
            <span class="uk-badge" :style="{background: getAccessColor(collection)}"></span>
            <span class="uk-text-capitalize uk-margin-small-left">{{ $t('Pages.Content.Availability.' + collection.access.charAt(0) + collection.access.toLowerCase().slice(1)) }}</span>
          </RouterLink> 
          <RouterLink tag="td" :to="toContent( collection )" class=" type">{{ $t('Pages.Collections.Types.' + collection.ref.seasonType.charAt(0) + collection.ref.seasonType.toLowerCase().slice(1)) }}</RouterLink>
          <td  v-if="isMutable">
            <ToggleButton class="uk-margin-small-top" @toggle="setStaging( collection, $event )" :value="collection.ref.staging" />
          </td>
          <td class="uk-text-center uk-width-small" v-if="isMutable">
            <div uk-icon="icon: pencil; ratio: 1" class="trash-icon uk-margin-small-right" @click.stop="clickEdit( collection )" />
            
            <span v-if="deletingList.includes( collection )" uk-spinner="ratio: 1" />
            <div v-else uk-icon="icon: trash; ratio: 1" class="trash-icon" @click.stop="deleteCollection(collection)" />

          </td>
        </DraggableItem>
    </baseTable>
    <div id="info-collection-modal" uk-modal="bg-close: false; esc-close: false">
      <div class="uk-modal-dialog uk-modal-body">
          <button class="uk-modal-close-default" type="button" uk-close></button>
            <div class="uk-text-warning" uk-icon="icon: info; ratio: 2" />
          <p class="uk-text-meta">{{ $t('DeleteModal.CannotDeleteCollection') }}</p>
      </div>
    </div>
    <div id="info-language-modal" uk-modal="bg-close: false; esc-close: false">
      <div class="uk-modal-dialog uk-modal-body">
          <button class="uk-modal-close-default" type="button" uk-close></button>
            <div class="uk-text-warning" uk-icon="icon: info; ratio: 2" />
          <p class="uk-text-meta">{{ $t('DeleteModal.DefaultLanguageCannotBeDeleted') }}</p>
      </div>
    </div>
    <div id="new-collection-modal" container="#collections" uk-modal="bg-close: false; esc-close: false; stack: true">
      <div class="uk-modal-dialog uk-margin-auto-vertical">
        <div class="uk-modal-header">
          <h2 class="uk-modal-title uk-text-left">{{ editCollection ? $t('Pages.Publishing.EditFolder') :  $t('Pages.Publishing.AddFolder')}}</h2>
        </div>
        <div class="uk-modal-body uk-flex uk-flex-column">
           <div class="uk-flex uk-flex-column uk-flex-middle">
            <ImgOrFallback class="uk-margin-bottom" :src="form.keyArtMedia ? form.keyArtMedia.file.url : icon"  height="160px" width="283px" imageClass='uk-border-rounded'/>
            <h5 class="uk-text-muted uk-margin-remove">{{$t('Generic.Labels.Image16x9')}}</h5>
            <button class="uk-button uk-button-primary uk-float-right uk-width-auto uk-margin-small-top" @click="openMediaPicker()">{{ $t('Pages.Publishing.UploadKeyArt') }}</button>
          </div>
          <form id="new-folder" class="uk-form-stacked uk-text-left" @submit.prevent="onSubmit();">
              <button class="uk-modal-close-default" type="button" uk-close @click="resetForm()"></button>
              <div class="uk-margin">
                  <label class="uk-form-label">{{$t('Pages.Publishing.FolderName') }} <span class="uk-text-danger">*</span></label>
                  <div class="uk-form-controls">
                    <div class="uk-inline uk-width-1-1">
                      <input class="uk-input" type="text"                           
                          :class="{ 'invalid-border': editCollection ? !this.form.name || this.form.name.trim().length<3 || ( this.form.name && this.form.name.length<3 ) : form.nameError }"
                          v-model.trim="form.name"
                          autocomplete="off"
                          required
                          @input="validateName($event.target.value)"
                          :placeholder="$t('Pages.Publishing.Placeholders.FolderName')" />
                          <span class="uk-form-icon uk-form-icon-flip invalid-icon" v-if="editCollection ? form.name.length < 3 : form.nameError" uk-icon="icon: warning" ></span>
                          <span class="uk-form-icon uk-form-icon-flip uk-width-auto uk-margin-medium-right inline-error-bg" v-if="editCollection ? form.name.length < 3 : form.nameError" >{{ form.nameError || 'Name should be atleast 3 characters' }}</span>
                    </div>
                  </div>
              </div>
              <div class="uk-margin uk-margin-remove-bottom">
                <label class="uk-form-label">{{ $t('Pages.Publishing.Description') }}</label>
                <div class="uk-form-controls">
                  <textarea class="uk-textarea" 
                      rows="5"
                      type="text"
                      v-model.trim="form.description"
                      autocomplete="off"
                      :placeholder="$t('Pages.Publishing.Placeholders.Description')" />
                </div>
              </div>
              <div class="uk-margin uk-flex">
                <div class="uk-width-small">
                  <label class="uk-form-label">{{ $t('Pages.Collections.FolderType') }}</label>
                    <select class="uk-select" v-model="form.type">
                      <option value="SEASON">{{ $t('Pages.Collections.Types.Season')}}</option>
                      <option value="CUSTOM">{{ $t('Pages.Collections.Types.Custom')}}</option>
                      <option value="SERIES">{{ $t('Pages.Collections.Types.Series')}}</option>
                      <option value="YEAR">{{ $t('Pages.Collections.Types.Year')}}</option>
                    </select>
                </div>
              <div class="uk-margin-medium-left">
                <label class="uk-form-label">{{ $t('Pages.Collections.Staging') }}</label>
                <ToggleButton class="uk-margin-small-top" :value="form.staging" @toggle="form.staging=$event" />
              </div>
            </div>
          </form>
        </div>
        <div class="uk-modal-footer uk-text-right">
          <button class="uk-button uk-button-default uk-modal-close uk-margin-right" @click="resetForm()">{{ $t('Actions.Cancel') }}</button>
          <button v-if="!isLoading" class="uk-button uk-button-primary" @click="onSubmit()">{{ $t('Actions.Save') }}</button>
          <button v-else class="uk-button uk-button-primary" type="button" disabled><div uk-spinner="ratio: 0.5"></div></button>
        </div>
      </div>
    </div>
    <div id="collection-lang-modal" container="collections" uk-modal="bg-close: false; esc-close: false; stack: true">
      <div class="uk-modal-dialog uk-margin-auto-vertical">
        <div class="uk-modal-header">
          <h2 class="uk-modal-title uk-text-left">{{ editLanguage ? $t('Languages.EditLanguage') : $t('Languages.AddLanguage') }}</h2>
        </div>
        <div class="uk-modal-body uk-flex uk-flex-column">
            <form id="new-language" class="uk-form-stacked uk-text-left" @submit.prevent="onLangCreate();">
              <button class="uk-modal-close-default" type="button" uk-close @click="resetLangForm()"></button>
              <div class="uk-margin">
                <span uk-grid >
                <label class="uk-form-label uk-width-1-2">{{$t('Languages.Language') }} <span class="uk-text-danger">*</span></label>
                <span v-if="langForm.languageError" class="uk-text-right uk-text-danger uk-width-1-2">{{$t('Generic.Errors.MandatoryField')}}<span class="uk-margin-small-left" uk-icon="icon: warning"></span></span>
                </span>
                <div uk-form-cutom="target: true" class="uk-form-custom" :class="{ 'invalid-border': langForm.languageError }">
                    <select class="uk-select uk-width-xlarge" @click="langForm.languageError = false" :class="{ 'uk-disabled': editLanguage }" id="content-lang" v-model="langForm.language">
                      <option
                        v-for="(lang, i) in editLanguage?SupportedLanguages:availableLanguages"
                        :key="i"
                        :value="lang"
                      >
                        {{ $t( `Languages.isoLanguageLabels.${lang}` ) }}
                      </option>
                    </select>
                     <span class="uk-select uk-width-xlarge uk-text-meta" :class="{ 'uk-disabled': editLanguage }" id="content-lang">{{ langForm.language ? $t( `Languages.isoLanguageLabels.${langForm.language}` ) : $t('Languages.SelectLanguage') }}</span>
                  </div>
              </div>
              <div class="uk-margin">
                  <label class="uk-form-label">{{$t('Generic.Labels.Title') }} <span class="uk-text-danger">*</span></label>
                  <div class="uk-form-controls">
                    <div class="uk-inline uk-width-1-1">
                      <input class="uk-input" type="text"
                          :class="{ 'invalid-border': editLanguage ? !langForm.title || langForm.titleError : langForm.titleError}"
                          v-model="langForm.title"
                          autocomplete="off"
                          required
                          @input="validateTitle($event.target.value)"
                          :placeholder="$t('Generic.Labels.Title')" />
                          <span class="uk-form-icon uk-form-icon-flip invalid-icon" v-if="editLanguage ? !langForm.title || langForm.titleError : langForm.titleError" uk-icon="icon: warning" ></span>
                          <span class="uk-form-icon uk-form-icon-flip uk-width-auto uk-margin-medium-right inline-error-bg" v-if="editLanguage ? !langForm.title || langForm.titleError : langForm.titleError" >{{langForm.titleError || 'Title should be atleast 3 characters'}}</span>
                    </div>
                  </div>
              </div>
              <div class="uk-margin">
                  <label class="uk-form-label">{{ $t('Generic.Labels.Headline') }}</label>
                  <div class="uk-form-controls">
                      <input class="uk-input" 
                          type="text"
                          v-model.trim="langForm.headline"
                          autocomplete="off"
                          :placeholder="$t('Generic.Labels.Headline')" />
                  </div>
              </div>
              <div class="uk-margin">
                  <label class="uk-form-label">{{ $t('Languages.Description') }}</label>
                  <div class="uk-form-controls">
                      <textarea class="uk-textarea" 
                      rows="2"
                      type="text"
                      v-model.trim="langForm.description"
                      autocomplete="off"
                      :placeholder="$t('Languages.Description')" />
                  </div>
              </div>
          </form>
        </div>
        <div class="uk-modal-footer uk-text-right">
          <button class="uk-button uk-button-default uk-modal-close uk-margin-right" @click="resetLangForm()">{{ $t('Actions.Cancel') }}</button>
          <button v-if="!isLoading" class="uk-button uk-button-primary" @click="onLangCreate()">{{ $t('Actions.Save') }}</button>
          <button v-else class="uk-button uk-button-primary" type="button" disabled><div uk-spinner="ratio: 0.5"></div></button>
        </div>
      </div>
    </div>
    <div v-if="showPicker" id="media-picker-collections-modal" container="#collections" uk-modal>
      <div class="uk-modal-dialog uk-modal-body uk-width-3-4">
      <ModalMediaPicker  media-class-filter="IMAGE" @cancel="closeModal()" @select="selectedKeyart( $event )" />
    </div>
  </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import PublishingDashboard from './PublishingDashboard';
import BaseTable from '@/components/tables/BaseTable.vue';
import DraggableItem from '@/components/draggable/DraggableItem.vue';
import ImageOrAbbrevation from '@/components/images/ImageOrAbbrevation.vue';
import ToggleButton from '@/components/buttons/ToggleButton.vue';
import Notification from '@/components/util/Notification';
import ImgOrFallback from '@/components/images/ImageOrFallback';
import { Permissions  } from '@/utils/enums';
import { SupportedLanguages } from '@/utils/content';

export default {
  name: 'CollectionView',
  components: {
    PublishingDashboard, BaseTable, DraggableItem, ImageOrAbbrevation, ToggleButton, ImgOrFallback, ModalMediaPicker: () => import('@/components/modals/ModalMediaPicker.vue')
  },
  props: {
    propertyId: { type: String, default: '' },
    id: { type: String, default: '' }
  },
  data() {
    return {
      loadingMore: false,
      fetching: false,
      deletingList: [],
      mutationRoles: [Permissions.ADMIN, Permissions.CONTENT_MANAGER],
      form: {
        name: '',
        type: 'SEASON',
        description: '',
        staging: false,
        nameError: '',
        keyArtMedia : ''
      },
      isLoading: false,
      showPicker: false,
      editCollection: null,
      editLanguage: null,
      icon: '',
      toggle: false,
      SupportedLanguages,
      langForm: {
        language: '',
        title: '',
        headline: '',
        description: '',
        languageError: '',
        titleError: ''
      },
      propertyData: this.collections,
      deleteLoading: false,
      deletingLangList: [],
      orgId: this.id
    }
  },
  computed: {
    langTableHeaders () {
      return [
      {
        name: this.$t('Languages.Language'),
        class: 'uk-width-medium'
      }, {
        name: this.$t('Generic.Labels.Title'), 
        class: 'uk-width-large'
      },
      {
        name: this.$t('Generic.Labels.Headline'), 
        class: 'uk-width-large uk-preserve-width'
      },
       {
        name: this.$t('Languages.Description'), 
        class: 'uk-width-2xlarge uk-preserve-width'
      },
      {
        name: this.$t('Actions.Actions'), 
        class: 'uk-width-small uk-text-center'
      }]
    },
    tableHeaders () {
      const headers = [
      {
        name: this.$t('Pages.Publishing.Headers.Type'),
        class: this.isAdmin ? '' : 'uk-width-small',
      }, {
        name: this.$t('Pages.Publishing.Headers.Name'), 
        class: ''
      }, {
        name: '', 
        class: 'uk-width-2xlarge uk-preserve-width' 
      }, {
        name: '', 
        class: this.isAdmin ? '' : 'uk-width-medium'
      }, {
        name: this.$t('Pages.Collections.Headers.Status'),
        class: 'uk-width-small'
      }, {
        name: this.$t('Pages.Collections.Headers.Availability'),
        class: 'uk-width-small'
      }]
      if (this.isMutable) {
        headers.unshift({
          name: '', 
          class: '' 
        })
        headers.push(
          {
            name: this.$t('Pages.Collections.Headers.Staging'), 
            class: 'uk-width-small' 
          },
          {
            name: this.$t('Actions.Actions'),
            class: 'uk-text-center uk-width-small',
            colspan: 2 
          })
      }
      return headers
    },
    ...mapState({
      collections: state => (state.venom.org.collections ) ? state.venom.org.collections : {},
      viewer: state => state.venom.org.viewer
    }),
    collectionNodes () {
      return this.collections.items?this.collections.items.nodes:[];
    },
    propertyMap () {
      const prop = {} 
      this.viewer?.permissions?.forEach(perm => {
        prop[perm.property.id] = this.isAdmin ? Permissions.ADMIN : perm.type
      })
      return prop
    },
    isAdmin () {
      return this.viewer?.role === Permissions.ADMIN ? true : false
    },
    isMutable () {
      return this.isAdmin || this.mutationRoles.includes(this.propertyMap[this.propertyId])
    },
    propertyLanguages () {
      const languages = [];
      let language = {};
      if (this.propertyData?.i18nName?.all?.length) {
        for ( const title of this.propertyData?.i18nName?.all ) {
            language.language = title.language;
            language.name = title.value;
            const description = this.propertyData?.i18nDescription?.all.find( l => l.language === title.language );
            language.description = description?.value;
            const headline = this.propertyData?.i18nHeadline?.all.find( l => l.language === title.language );
            language.headline = headline?.value;
            languages.push( language );
            language = {};
        }
      }
      const sortedList = [...languages]?.sort((a, b) => this.langTitle( a ).toString().localeCompare(this.langTitle( b ).toString().toString())) || [];
      return sortedList.sort( (x,y)=> x.language === 'en-US' ? -1 : y.language === 'en-US' ? 1 : 0 );
    },
    availableLanguages () {
            const langArray = this.propertyLanguages.map( lang => lang.language );
            return SupportedLanguages.filter( lang => !langArray.includes( lang ) );
        },
  },
  methods: {
    navigateBack(){
      let url = `/organizations/${this.orgId}/publishing/`;
      this.$router.push(url);
    },

    isDisabled( name ) {
      return !name || name.trim().length<2 || ( name && name.length<2 );
    },
    async fetchOrgCollections() {
      this.fetching = true;
      await this.$store.dispatch('fetchCollections', { propertyId: this.propertyId });
      this.fetching = false;
    },
    getAccessColor ({ access }) {
      let indicator = 'none';
      if ( access === 'PUBLIC' ) {
        indicator = 'green';
      } else if ( access === 'PRIVATE' ) {
        indicator = 'red';
      } else if ( access === 'TESTING' ) {
        indicator = 'orange';
      }
      return indicator;
    },
    infiniteScroll () {
      window.onscroll = () => {
        let bottomOfWindow = (window.innerHeight + window.scrollY) >= document.body.offsetHeight-20;
        if ( bottomOfWindow && this.collections.items?.pageInfo?.hasNextPage && !this.loadingMore ) {
          this.loadMore();
        } 
      }
    },
    async loadMore () {
      this.loadingMore = true;
      await this.$store.dispatch('fetchCollections', { propertyId: this.propertyId, after: this.collections.items.pageInfo.endCursor });
      this.loadingMore = false;
    },
    async drop ( draggableContent, collectionId ) {
      const { id, direction } = draggableContent;
      if ( id !== collectionId ) {
        this.fetching = true;
        await this.$store.dispatch('setContentPosition', { variables: this.queryVariables( direction, id, collectionId ), propertyId: this.propertyId });
        this.fetching = false;
      }
    },
    queryVariables ( direction, draggableId, collectionId ) {
      return direction === 'upwards' ? { input : { content: draggableId, before: collectionId } } : { input : { content: draggableId, after: collectionId } };
    },
    toContent ( collection ) {
      return { name: 'Content', params: { id: this.orgId, propertyId: this.propertyId, collectionId: collection.id } };
    },
    collectionName ( collection ) {
      return collection.ref.i18nName ? collection.ref.i18nName.localized.value : '';
    },
    deleteCollectionRef ( collection ) {
      this.deletingList.forEach( i=> {
        if( i.id == collection.id )
          this.deletingList.splice(i,1);
      });
    },
    deleteLangRef ( lang ) {
      this.deletingLangList.forEach( l=> {
        if( l.language== lang.language )
          this.deletingLangList.splice(l,1);
      });
    },
    async deleteCollection( collection ) {
      if ( collection.ref.items.totalCount > 0 ) {
        window.UIkit.modal('#info-collection-modal').show();
      } else {
        const UIkit = window.UIkit;
        UIkit.modal.confirm(this.$t( 'DeleteModal.ConfirmDelete', { name: this.collectionName ( collection ) }), { labels: {
                cancel: this.$t('Actions.Cancel'), ok: this.$t('Actions.Delete')
            }}).then( async () => {
          this.deletingList.push( collection );
          const response = await this.$store.dispatch( 'deleteCollection', { collectionId: collection.id, propertyId: this.propertyId } );
          if ( response ) {
            this.deleteCollectionRef( collection );
            Notification.showNotification(this.$t( 'DeleteModal.Removed'), this.$t( 'DeleteModal.HasBeenRemoved', { name: this.collectionName ( collection ) }));
          } else {
            Notification.showNotification(this.$t( 'DeleteModal.NotRemoved'), response?.errors[0]?.message, 'error');
          }
        },()=>{});
      }
    },
    async setStaging( collection, staging ) {
      await this.$store.dispatch('updateCollection', { variables: { staging, collection: collection.id }, propertyId: this.propertyId });
    },
    validateForm () {
      let isValid = true
      this.validateName(this.form.name)
      if (this.form.nameError ) {
        isValid = false
      } else if (this.editCollection && !this.form.name) {
        this.nameError = this.$t('Generic.Errors.Min3Chars')
      } else if (!this.editCollection ) {
        if (this.form.nameError ) {
          isValid = false
        } else if (!this.form.name) {
          this.form.nameError = !this.form.name ? this.$t('Generic.Errors.Min3Chars') : false
          isValid = false
        }
      }
      return isValid
    },
    validateTitle( name ) {
      if (this.langForm.titleError) {
        this.langForm.titleError = name.length >=3 ? false : this.$t('Generic.Errors.Min3Chars');
      } else {
        if ( name ) {
          this.langForm.titleError = name.length<3 || !name.trim() ? this.$t('Generic.Errors.Min3Chars') : false;
        }
      }
    },
    async onSubmit() {
      try {
        if (!this.validateForm()) return false
        this.isLoading = true;
        const newCollection = this.editCollection ? await this.$store.dispatch("updateCollection", {
          variables: {
            i18nName: [ { language: 'en-US', value: this.form.name } ],
            i18nDescription: [ { language: 'en-US', value: this.form.description } ],
            keyArt: this.form.keyArtMedia ? { id: this.form.keyArtMedia.id } : null,
            type: this.form.type,
            staging: this.form.staging,
            collection: this.editCollection.id
          }, propertyId: this.propertyId
        }) : this.$store.dispatch("createCollection", {
          name: this.form.name,
          description: this.form.description,
          keyArt: this.form.keyArtMedia ? { id: this.form.keyArtMedia.id } : null,
          type: this.form.type,
          staging: this.form.staging,
          property: this.propertyId
        });
        window.UIkit.modal('#new-collection-modal').hide();
        if( newCollection && !this.editCollection )
          Notification.showNotification(this.$t('Actions.Created'), this.$t('Pages.MediaLibrary.FolderCreated', { name: this.form.name }));
        else
          Notification.showNotification(this.$t('Actions.Updated'), `${this.form.name} ` + this.$t('Actions.Updated').toLowerCase());
        this.isLoading = false;
        this.resetForm();
      } catch (err) {
        this.resetForm();
      }
    },
    validateName( name ) {
      if (this.form.nameError) {
        this.form.nameError = name && name.length >=3 ? false : this.$t('Generic.Errors.Min3Chars');
      } else {
          this.form.nameError = name && name.length<3 || !name.trim() ? this.$t('Generic.Errors.Min3Chars') : false;
        }
    },
    closeModal () {
      window.UIkit.modal('#media-picker-collections-modal').hide();
      window.UIkit.modal('#new-collection-modal').show();
      setTimeout( function() {
        this.showPicker = false;
      }, 100 );
    },
    selectedKeyart ( media ) {
      this.form.keyArtMedia = media;
      this.closeModal();
    },
    openMediaPicker () {
      this.showPicker = true;
      setTimeout( () => {
        window.UIkit.modal('#media-picker-collections-modal').show();
      }, 100 );
    },
    resetForm () {
      this.form.name = '';
      this.form.description = '';
      this.form.keyArtMedia = '';
      this.form.type = 'SEASON';
      this.form.staging = false;
      this.form.nameError = '';
      this.icon = '';
      this.editCollection= null;    
    },
    resetLangForm () {
      this.langForm.language = '';
      this.langForm.title = '';
      this.langForm.description = '';
      this.langForm.headline = '';
      this.langForm.titleError = '';
      this.langForm.languageError = '';
      this.editLanguage= null;    
    },
    clickEdit ( collection ) {
      this.editCollection = collection;
      window.UIkit.modal('#new-collection-modal').show();
      this.form.name = this.collectionName(collection);
      this.form.type = collection.ref.seasonType;
      this.form.staging = collection.ref.staging;
      this.form.description = collection.ref.i18nDescription?.localized?.value || '';
      this.icon = collection.ref.icon ? collection.ref.icon.url : '';
    },
    clickEditLang ( lang ) {
      this.editLanguage = lang;
      window.UIkit.modal('#collection-lang-modal').show();
      this.langForm.title = lang.name;
      this.langForm.headline = lang.headline;
      this.langForm.description = lang.description;
      this.langForm.language = lang.language;
    },
    langTitle( lang ) {
      return this.$t( `Languages.isoLanguageLabels.${lang.language}` )
    },
    async onLangCreate () {
      let isValid = true;
      if (this.isDisabled(this.langForm.title)) {
        this.langForm.titleError = this.$t('Generic.Errors.Min3Chars');
        isValid = false;
      }
      if (!this.langForm.language) {
        this.langForm.languageError = this.$t('Generic.Errors.MandatoryField')
        isValid = false;
      }

      else if (this.langForm.titleError) isValid = false;
      if ( isValid ) {
      try {
        const property = await this.$store.dispatch("updateProperty", {
          i18nName: [ { language: this.langForm.language, value: this.langForm.title } ],
          i18nHeadline: [ { language: this.langForm.language, value: this.langForm.headline } ],
          i18nDescription: [ { language: this.langForm.language, value: this.langForm.description } ],
          organization: this.orgId,
          property: this.propertyId,
          isProperty: false
        });
        window.UIkit.modal('#collection-lang-modal').hide();
        if ( property && !this.editLanguage )
          Notification.showNotification(this.$t( 'Generic.Messages.Successful'), this.$t( 'Languages.Notification.NewLanguage'));
        else
          Notification.showNotification(this.$t( 'Generic.Messages.Successful'), this.$t( 'Languages.Notification.UpdateLanguage'));
        this.resetLangForm();
      } catch (err) {
        this.resetLangForm();
      }}
      else return isValid;
    },
    async deleteLanguage ( language ) {
      if ( language.language === 'en-US' ) {
        window.UIkit.modal('#info-language-modal').show();
      } else {
      window.UIkit.modal.confirm(this.$t( 'DeleteModal.ConfirmDelete', { name: this.langTitle( language ) }), { labels: {
                cancel: this.$t('Actions.Cancel'), ok: this.$t('Actions.Delete')
            }}).then( async () => {
          this.deletingLangList.push( language );
          const response = await this.$store.dispatch( 'updateProperty', { i18nName: { language: language.language, delete: true },
                        i18nHeadline: { language: language.language, delete: true },
                        i18nDescription: { language: language.language, delete: true },
                        organization: this.orgId,
                        isProperty: false,
                        property: this.propertyId } );
          if ( response ) {
            Notification.showNotification(this.$t( 'DeleteModal.Removed'), this.$t( 'DeleteModal.HasBeenRemoved', { name: this.langTitle( language ) }));
          } else {
            Notification.showNotification(this.$t( 'DeleteModal.NotRemoved'), this.$t( 'DeleteModal.CouldNotRemove', { name: this.langTitle( language ) }), 'error');
          }
        },()=>{});
        }
    }
  },
  mounted() {
    this.fetchOrgCollections();
    document.documentElement.scrollTop = 0
    this.infiniteScroll();
  },
  watch: {
    collections: {
      handler ( newVal ) {
        if ( newVal ) {
          this.propertyData = newVal;
        }
      },
      deep: true,
      immediate: true
      }
    }
}
</script>

<style lang="scss" scoped>
.icon-image {
  width: 85px;
  height: 48px;
}
.trash-icon {
  height: 20px; 
  width: 20px;
  cursor: pointer;
}
.icon-drag {
  height: 20px; 
  width: 20px;
  cursor: move;
}
.uk-badge {
  height: 11px; 
  min-width: 11px;
}
.icon,.name,.type,.access, .back-link {
  cursor: pointer;
}
.new-collection-btn {
  padding: 0 20px;
}
.uk-table-divider>:first-child>tr:not(:first-child), .uk-table-divider>:not(:first-child)>tr, .uk-table-divider>tr:not(:first-child) {
  border-bottom: 1px solid var(--border-color);
}

.uk-text-truncate {

    color:var(--app-primary-color);
    cursor:pointer;

}
.uk-text-truncate:hover {
    
    color:var(--text-color);
    
}
</style>