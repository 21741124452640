<template>
    <div 
      class="js-filter uk-grid-small uk-child-width-1-2@s uk-child-width-1-3@m uk-child-width-1-4@l uk-child-width-1-5@xl"
      uk-scrollspy="target: > div; cls: uk-animation-slide-top-medium; delay: 100; offset-top: 2000;"
      uk-grid>
      <div
        v-for="(card, i) in orgData"
        :key="i"
      >
        <publishing-card
        :name="card.name" 
        :count="card.count || 0" 
        :logo="card.logo" 
        />
      </div>
    </div>
</template>

<script>
import PublishingCard from '../../components/cards/PublishingCard';
import  { mapState } from 'vuex'

export default {
  name: 'PublishingDashboard',
  components: {
      PublishingCard
  },
  props: {
      propertiesData: { required: true }
  },
  computed: {
    ...mapState({
      featureSupport: state => state.venom.org.options?.featureSupport
    }),
    orgData() {
        const data = [{ name: this.$t('Pages.Publishing.Headers.Folders'), count: this.propertiesData?.propertiesConnection ? this.propertiesData?.propertiesConnection?.totalCount : this.propertiesData?.folders?.totalCount, logo: 'icon: folder; ratio: 1.5' },
        { name: this.$t('Pages.Publishing.Headers.Videos'), count: this.propertiesData?.vods?.totalCount, logo: 'icon: camera; ratio: 1.5' },
        { name: this.$t('Pages.Publishing.Headers.LiveStreams'), count: this.propertiesData?.streams?.totalCount, logo: 'icon: video-camera; ratio: 2.1' }]
        if (this.featureSupport?.interactiveVideos) data.push({ name: this.$t('Pages.Publishing.Headers.InteractiveVideos'), count: this.propertiesData?.interactives?.totalCount, logo: 'icon: play-circle; ratio: 1.5' })
        if (this.featureSupport?.ads) data.push({ name: this.$t('Pages.Publishing.Headers.Ads'), count: this.propertiesData?.ads?.totalCount, logo: 'icon: tv; ratio: 1.5' })
        if (this.featureSupport?.ar) data.push({ name: this.$t('Pages.Publishing.Headers.AR'), count: this.propertiesData?.ar?.totalCount, logo: 'ar' })
        return data
    }
  }
}
</script>
